import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SystemErrors, SystemMessages } from '@common/enums/system-messages.enum';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) { }

  show(message: SystemMessages | SystemErrors | string): void {
    this.snackBar.open(message as string, null, {
      duration: 4000
    });
  }

  showError(message: SystemErrors | string): void {
    this.show(message);
  }

  showMessage(message: SystemMessages): void {
    this.show(message);
  }
}
