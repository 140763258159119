import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotLoggedGuard } from '@common/guards/not-logged.guard';
import { LoggedGuard } from '@common/guards/logged.guard';

const routes: Routes = [
  {
    path: '',
    data: { base: true },
    canActivate: [LoggedGuard],
    loadChildren: () => import('./main/main.module')
    .then(m => m.MainModule)
  },
  {
    path: 'external',
    canActivate: [NotLoggedGuard],
    loadChildren: () => import('./external-pages/external-pages.module')
    .then(m => m.ExternalPagesModule)
  },
  { path: '**', redirectTo: '/external/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
