import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SessionService } from '../services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedGuard  {
  constructor(private sessionService: SessionService,
              private router: Router) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (this.sessionService.isAuth$.value) {
      return true;
    } else {
      return this.router.parseUrl('/external/login');
    }
  }
}
