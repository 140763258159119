import { Injectable } from '@angular/core';
import { MyCookiesService } from '../my-cookies.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { SessionData } from '@common/interfaces/session-data.interface';

@Injectable({
  providedIn: 'root'
})

export class SessionService {
  sessionData$ = new BehaviorSubject<SessionData | null>(null);
  isAuth$ = new BehaviorSubject<boolean>(null);

  get token(): string {
    return this.sessionData$.value ? this.sessionData$.value.authToken : null;
  }

  get userId(): number {
    return this.sessionData$.value ? this.sessionData$.value.userId : null;
  }

  get role(): string {
    return this.sessionData$.value ? this.sessionData$.value.role : null;
  }

  constructor(private cookies: MyCookiesService) {
    const authToken = this.getTokenFromCookies();
    const userId = +this.getUserIdFromCookies();
    const role = this.getRoleFromCookies();

    this.setSessionData({
      authToken, userId, role
    });
    this.sessionData$.subscribe(data => {
      this.isAuth$.next(!!(data && data.authToken && data.userId && data.role));
    });
  }

  setSessionData(sessionData: SessionData, saveToCookie?: boolean): void {
    if (sessionData.userId && sessionData.authToken && sessionData.role) {
      this.sessionData$.next(sessionData);
      if (saveToCookie) {
        this.setUserIdToCookie(sessionData.userId);
        this.setTokenToCookie(sessionData.authToken);
        this.setRoleToCookie(sessionData.role);
      }
    } else {
      this.deleteSession();
    }
  }

  getSessionDataObs(): Observable<SessionData | null> {
    return this.sessionData$.pipe(
      startWith(this.sessionData$.value)
    );
  }

  setUserIdToCookie(userId): void {
    this.cookies.put('userId', userId);
  }

  setTokenToCookie(authToken): void {
    this.cookies.put('authToken', authToken);
  }

  setRoleToCookie(role): void {
    this.cookies.put('role', role);
  }

  deleteSession(): void {
    this.sessionData$.next(null);
    this.cookies.remove('userId');
    this.cookies.remove('authToken');
    this.cookies.remove('userRole');
  }

  getTokenFromCookies(): string {
    return this.cookies.get('authToken');
  }

  getUserIdFromCookies(): string {
    return this.cookies.get('userId');
  }

  getRoleFromCookies(): string {
    return this.cookies.get('role');
  }
}
