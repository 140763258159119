import { Inject, Injectable } from '@angular/core';
import { SessionService } from '../session/session.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { cleanObject } from '@common/utils/common.utils';

interface SendQueryParams {
  type: 'get' | 'post' | 'put' | 'patch' | 'delete';
  url: string;
  body?: any;
  params?: any;
  headers?: any;
  pushHeaders?: any;
  options?: any;
  withoutAuthorization?: boolean;
}

interface GetParams {
  url: string;
  params?: any;
  headers?: any;
  pushHeaders?: any;
  options?: any;
  withoutAuthorization?: boolean;
}

interface PostParams {
  url: string;
  body?: any;
  params?: any;
  headers?: any;
  pushHeaders?: any;
  options?: any;
}

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

@Injectable({ providedIn: 'root' })
export class ApiService {

  constructor(
    private httpClient: HttpClient,
    private sessionService: SessionService,
    @Inject('API_ROOT_URL') private apiRootUrl: string
  ) { }

  get<T>(params: GetParams): Observable<T> {
    return this.sendQuery<T>({
      type: 'get',
      ...params
    });
  }

  post<T>(params: PostParams, type?: string): Observable<T> {
    return this.sendQuery<T>({
      type: 'post',
      ...params
    },
      type
    );
  }

  put<T>(params: PostParams): Observable<T> {
    return this.sendQuery<T>({
      type: 'put',
      ...params
    });
  }

  patch<T>(params: PostParams): Observable<T> {
    return this.sendQuery<T>({
      type: 'patch',
      ...params
    });
  }

  delete<T>(params: GetParams): Observable<T> {
    return this.sendQuery<T>({
      type: 'delete',
      ...params
    });
  }

  sendQuery<T>(params: SendQueryParams, responseType = 'json'): Observable<T> {
    let httpHeaders;
    if (!params.headers) {
      httpHeaders = new HttpHeaders({ ...DEFAULT_HEADERS, ...params.pushHeaders });
    } else {
      httpHeaders = new HttpHeaders(params.headers);
    }
    const token = this.sessionService.token;
    if (token && !params.withoutAuthorization) {
      const tokenHeader = `${token}`;
      httpHeaders = httpHeaders.append('Authorization', tokenHeader);
    }
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });
    if (params.params) {
      Object.keys(params.params)
        .forEach(param => {
          httpParams = httpParams.append(param, params.params[param]);
        });
    }
    const url = `${this.apiRootUrl}${params.url}`;
    const queryParams = cleanObject({
      headers: httpHeaders, params: httpParams, options: params.options, responseType
    });
    let query;
    if (params.type === 'get' || params.type === 'delete') {
      if (params.type === 'get') {
        query = this.httpClient[params.type](url, queryParams);
      } else if (params.type === 'delete') {
        query = this.httpClient[params.type](url, queryParams);
      }
    } else {
      query = this.httpClient[params.type](url, params.body, queryParams);
    }

    return query;
  }
}
