import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '@common/services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class NotLoggedGuard  {
  constructor(private sessionService: SessionService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {
    if (this.sessionService.isAuth$.value) {
      return this.router.parseUrl('/');
    } else {
      return true;
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {
    if (this.sessionService.isAuth$.value) {
      return this.router.parseUrl('/');
    } else {
      return true;
    }
  }
}
