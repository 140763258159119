import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { AuthApiService } from '@common/services/api/auth-api.service';
import { ErrorHandlerService } from '@common/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthApiService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((err: HttpErrorResponse, caught: Observable<any>): ObservableInput<{}> => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.authService.logOut();
          } else {
            this.errorHandlerService.handleError(err);
          }

          return throwError(err);
        })
      );
  }
}

export const httpErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorInterceptor,
  multi: true
};
