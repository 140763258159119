import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
// Modules.
import { SharedMaterialModule } from './shared-material.module';

// Pipes
import { CUSTOM_BREAKPOINTS } from '../custom-breakpoints';
// import {
//   CustomLayoutDirective,
//   CustomFlexDirective,
//   CustomLayoutGapDirective,
//   CustomShowHideDirective
// } from '../custom-fx.directive';
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedMaterialModule,
    MomentModule
  ],
  declarations: [
    // Directives
    // CustomLayoutDirective,
    // CustomFlexDirective,
    // CustomLayoutGapDirective,
    // CustomShowHideDirective
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedMaterialModule,
    MomentModule,
    // Directives
    // CustomLayoutDirective,
    // CustomFlexDirective,
    // CustomLayoutGapDirective,
    // CustomShowHideDirective
  ]
})
export class SharedModule {
}
