import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { environment } from '../environments/environment';

import { CookieService } from 'ngx-cookie-service';

import { MatIconRegistry } from '@angular/material/icon';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from './shared/shared.module';

import { httpErrorInterceptorProvider } from '@common/interceptors/http-error.interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    InfiniteScrollModule,
    QuillModule.forRoot(),
    SharedModule
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    { provide: 'API_ROOT_URL', useValue: environment.api.baseUrl },
    httpErrorInterceptorProvider,
    CookieService
  ]
})
export class AppModule {
  constructor(private matIconRegistry: MatIconRegistry) {
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
  }

}
