// import { MatProgressButtonOptions } from 'mat-progress-buttons';

export function isExists(value): boolean {
  return value !== null && value !== undefined;
}

export function cleanObject<T = object>(obj: object): T {
  const cleanedObject = {};

  Object
  .keys(obj)
  .forEach(key => {
    if (isExists(obj[key])) {
      cleanedObject[key] = obj[key];
    }
  });

  return cleanedObject as T;
}

export function trimString(str): string {
  if (str && str.trim) {
    return str.trim();
  }

  return str;
}

export function trimObj<T>(model): T {
  const trim = model => {
    if (typeof model === 'string') {
      return trimString(model);
    } else if (Array.isArray(model)) {
      return model.map(item => trim(item));
    } else if (model instanceof Object) {
      return Object
      .keys(model)
      .reduce((prev, curr) => {
        prev[curr] = trim(model[curr]);

        return prev;
      }, {});
    } else {
      return model;
    }
  }

  return trim(model) as T;
}

export const tableColsSizes = {
  initSizes: function (sizes) {
    const sizesArr = ['Lg', 'Md', 'Sm', 'Xs', 'Xxs'];
    const result = { ...sizes };
    Object.keys(result).forEach((key) => {
      let last = result[key].cols;
      sizesArr.forEach((size) => {
        if (!result[key]['cols' + size] && result[key]['cols' + size] !== 0) {
          result[key]['cols' + size] = last;
        } else {
          last = result[key]['cols' + size];
        }
      });
    });
    return result;
  },
  calculateColsWidth: function (colsSizes, size = '') {
    let fixedColsWidth = 0;
    let adaptiveCols = [];
    Object.keys(colsSizes).forEach((key) => {
      if (colsSizes[key].fixed) {
        fixedColsWidth += colsSizes[key].fixed;
      } else {
        adaptiveCols.push({
          name: key,
          col: colsSizes[key]
        });
      }
    });
    const colsCount = adaptiveCols.reduce((sum, current) => {
      return sum + current.col['cols' + size];
    }, 0);
    if (colsCount) {
      Object.keys(colsSizes).forEach((key) => {
        if (colsSizes[key]['cols' + size] || colsSizes[key].fixed) {
          if (fixedColsWidth) {
            if (colsSizes[key].fixed) {
              colsSizes[key].width = colsSizes[key].fixed + 'px';
            } else {
              colsSizes[key].width = `calc(${(100 / colsCount) * colsSizes[key]['cols' + size]}% -
              ${(fixedColsWidth / colsCount) * colsSizes[key]['cols' + size]}px)`;
            }
          } else {
            colsSizes[key].width = ((100 / colsCount) * colsSizes[key]['cols' + size]) + '%';
          }
        }
      });
    }
  },
  calculateColsCount(colsNames, colsSizes, size = '') {
    const result = colsNames.reduce((sum, current) => {
      return sum + colsSizes[current]['cols' + size];
    }, 0);
    return result;
  }
};


export function getObjFieldByPath(obj: any, path: Array<string>): any {
  return path.reduce((prev, curr) => {
    prev = prev ? prev[curr] : null;

    return prev;
  }, { ...obj });
}

export function setObjFieldByPath<T>(obj: T, path: Array<string>, value: any): T {
  const setVal = (currentObj, pathIndex) => {
    if (currentObj instanceof Object && path[pathIndex]) {
      return {
        ...currentObj,
        [path[pathIndex]]: setVal(obj[path[pathIndex]], pathIndex + 1)
      }
    } else {
      return value;
    }
  }

  return setVal(obj, 0);
}
