import { Injectable } from '@angular/core';
import { SessionService } from '../session/session.service';
import { Router } from '@angular/router';
import { ApiService } from '@common/services/api/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionData } from '@common/interfaces/session-data.interface';
import { HttpResponse } from '@common/interfaces/http-response.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(
    private router: Router,
    private apiService: ApiService,
    private sessionService: SessionService
  ) {
  }

  logIn(params: { email: string, password: string }): Observable<SessionData> {
    return this.apiService.post<HttpResponse<SessionData>>({
      body: params,
      url: 'v1/client/login'
    })
      .pipe(
        map(value => value.result)
      );
  }

  logOut(): void {
    this.sessionService.deleteSession();
    void this.router.navigate(['/external/login']);
  }
}
