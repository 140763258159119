import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

const PREFIX = 'aomi-admin_';

@Injectable({
  providedIn: 'root'
})

export class MyCookiesService {
  constructor(private cookies: CookieService) {}

  get(name: string): string {
    const value = this.cookies.get(PREFIX + name);

    return value === 'undefined' ? null : value;
  }

  put(name: string, val: any): void {
    this.cookies.set(PREFIX + name, val, new Date(`${new Date().getFullYear() + 10}`));
  }

  remove(name: string): void {
    this.cookies.delete(PREFIX + name);
  }
}
