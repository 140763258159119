export enum SystemMessages {
  SEAL_CREATED = 'Seal created',
  SEAL_EDITED = 'Seal edited',
  SEAL_DELETED = 'Seal deleted',
  SUBSECTION_CREATED = 'Subsection created',
  SUBSECTION_EDITED = 'Subsection edited',
  SUBSECTION_DELETED = 'Subsection deleted',
  PHRASE_CREATED = 'Phrase created',
  PHRASE_EDITED = 'Phrase edited',
  PHRASE_DELETED = 'Phrase deleted',
  GEOGRAPHY_EDITED = 'Geography edited',
  GEOGRAPHY_CREATED = 'Geography created',
  GEOGRAPHY_DELETED = 'Geography deleted',
  ONOMATOPE_CREATED = 'Onomatopoeia created',
  ONOMATOPE_EDITED = 'Onomatopoeia edited',
  IDIOM_EDITED = 'Idiom edited',
  IDIOM_CREATED = 'Idiom created',
  IDIOM_DELETED = 'Idiom deleted',
  SOUL_EDITED = 'Japanese soul edited',
  CURVES_EDITED = 'Curves edited',
  SOUL_CREATED = 'Japanese soul created',
  SOUL_DELETED = 'Japanese soul deleted',
  TONGUE_TWISTER_CREATED = 'Tongue twister created',
  TONGUE_TWISTER_EDITED = 'Tongue twister edited',
  TONGUE_TWISTER_DELETED = 'Tongue twister deleted',
  SURNAME_CREATED = 'Surname created',
  SURNAME_DELETED = 'Surname deleted',
  SURNAME_EDITED = 'Surname edited',
  KATAKANA_CREATED = 'Katakana created',
  KATAKANA_DELETED = 'Katakana deleted',
  KATAKANA_EDITED = 'Katakana edited',
  LEVEL_DISPLAYED = 'Lesson will be shown in app',
  LEVEL_NOT_DISPLAYED = 'Lesson will not be shown in app',
  LEVEL_DELETED = 'Lesson deleted',
  LEVEL_EDITED = 'Lesson edited',
  BLOCK_EDITED = 'Section edited',
  BLOCK_DELETED = 'Section deleted',
  BLOCK_CREATED = 'Section created',
  TRAINING_EDITED = 'Training edited',
  TRAINING_CREATED = 'Training created',
  TRAINING_DELETED = 'Training deleted',
  BLOCK_DISPLAYED = 'Section will be shown in app',
  BLOCK_NOT_DISPLAYED = 'Section will not be shown in app',
  TABLE_ORDER_CHANGE_DISABLED = 'Order changing available only when the table is not filtered or sorted',
  SLANG_CREATED = 'Slang created',
  SLANG_EDITED = 'Slang edited',
  SLANG_DELETED = 'Slang deleted',
  MAIN_TEXT_EDITED = 'Main text edited',
  NOTIFICATIONS_EMAIL_SENT = 'Notification has been successfully sent by email',
  NOTIFICATIONS_PUSH_SENT = 'Push-notification has been successfully sent',
  METHODOLOGY_EDITED = 'Methodology edited',
  METHODOLOGY_DELETED = 'Methodology deleted'
}

export enum SystemErrors {
  FILES_PROCESSING = 'Audio files are currently being processed, please wait',
  FILES_PROCESSING_ERROR = 'An error occurred during audio files processing, please try again',
  DEFAULT = 'Internal error. Please try again later',
  WRONG_FILE_FORMAT_OR_SIZE = 'Wrong file format or size',
  WRONG_FILE_RESOLUTION = 'Wrong file resolution',
  AUDIO_TOO_SHORT = 'Audio is too short. Please upload longer audio',
  SEAL_USED = 'Seal is already in use',
  NO_MORE_THEN_FIVE_BLOCKS_DISPLAY = 'Can\'t display more then 5 blocks' ,
  EXPRESSION_USED = 'Expression is used in trainings, you cannot delete it',
  FILE_UPLOADING_ERROR = 'Files uploading error',
  NOT_ENOUGH_LEVELS = 'Lesson must have at least 5 blocks to be shown in app',
  FILE_ERROR = 'Unpredictable file error',
  WRONG_EMAIL_OR_PASSWORD = 'Incorrect login or password',
  SECTION_LIMIT = 'You can have only 25 phrases in single training',
  TRAINING_LIMIT = 'You can have only 25 trainings in single unit',
  SECTION_MIN = 'Training must have at least 6 phrases to be saved',
  PHRASE_WITHOUT_LIBRARY = 'Choose the library first'
}
