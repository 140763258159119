import { Injectable } from '@angular/core';
import { SnackbarService } from '@common/services/snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SystemErrors } from '@common/enums/system-messages.enum';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  constructor(private snackbarService: SnackbarService) { }

  handleError(error: HttpErrorResponse): void {
    const err = error?.error?.error;
    const errDetails = err?.details;

    if (err) {
      if (err.type === 'voice-recording-too-short') {
        this.snackbarService.showError(SystemErrors.AUDIO_TOO_SHORT);

        return;
      }

      if (err.type === 'invalid-blocks-count') {
        this.snackbarService.showError(SystemErrors.NOT_ENOUGH_LEVELS);

        return;
      }

      if (err.type === 'invalid-trainings-count') {
        this.snackbarService.showError(error?.error.error.message);

        return;
      }

      if (err.type === 'validation-error') {
        if (errDetails[0]?.type === 'already-used' && errDetails[0]?.target === 'sealId') {
          this.snackbarService.showError(SystemErrors.SEAL_USED);

          return;
        }

        if (errDetails[0]?.type === 'cannot-be-displayed' && errDetails[0]?.target === 'displayed') {
          this.snackbarService.showError(SystemErrors.NO_MORE_THEN_FIVE_BLOCKS_DISPLAY);

          return;
        }

        if (errDetails[0]?.type === 'in-use' && errDetails[0]?.target === 'sealId') {
          this.snackbarService.showError(errDetails[0]?.message);

          return;
        }

        if (errDetails[0]?.type === 'in-use' && errDetails[0]?.target === 'expressionId') {
          this.snackbarService.showError(SystemErrors.EXPRESSION_USED);

          return;
        }

        if (errDetails[0]?.target === 'recordingId') {
          if (errDetails[0]?.type === 'not-available') {
            this.snackbarService.showError(SystemErrors.FILES_PROCESSING);

            return;
          }

          if (errDetails[0]?.type === 'pitch-analysis-failed') {
            this.snackbarService.showError(SystemErrors.FILES_PROCESSING_ERROR);

            return;
          }
        }
      }
    }

    this.snackbarService.showError(SystemErrors.DEFAULT);
  }
}
